@import '@angular/cdk/overlay-prebuilt.css';
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "swiper/css";
@import "swiper/css/pagination";
@import "swiper/css/lazy";
@import "swiper/css/virtual";

/* utilities makes styles purgeable */
@layer utilities {
  .h-30r {
    height: 30rem;
  }
  .h-40r {
    height: 40rem;
  }
  .h-45r {
    height: 45rem;
  }
  .h-50r {
    height: 50rem;
  }
  .h-55r {
    height: 55rem;
  }
  .max-w-5r {
    max-width: 5rem;
  }
  .max-w-8xl {
    max-width: 90rem;
  }
  .w-max-38r {
    max-width: 38rem;
  }
  .min-h-screen {
    min-height: 100vh;
  }
  .min-h-50 {
    min-height: 50px;
  }
  .min-h-5r {
    min-height: 5rem;
  }
  .min-h-8r {
    min-height: 8rem;
  }
  .min-w-6r {
    min-width: 6rem;
  }
  .min-w-7r {
    min-width: 7rem;
  }
  .min-w-8r {
    min-width: 8rem;
  }
  .min-w-14r {
    min-width: 14rem;
  }
  .min-h-12r {
    min-height: 12rem;
  }
  .min-h-16r {
    min-height: 16rem;
  }
  .text-black-important {
    color: black !important;
  }
  .hide_input {
    position: absolute;
    left: -9999999px;
  }
  .z-index-99999 {
    z-index: 99999;
  }

  /**
   Material datepicker override
  */
  .mat-datepicker-content {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    border-radius: .375rem !important;
    border: 1px solid #E5E7EB !important;
  }
  /* calendar content */
  .mat-datepicker-content .mat-calendar {
    height: 300px !important;
  }
  /* Calendar month - year title */
  .mat-calendar-controls .mat-focus-indicator.mat-calendar-period-button {
    font-weight: 500;
    font-size: 1rem;
    color: #111827;
    padding: 0;
  }
  .mat-calendar-controls .mat-focus-indicator.mat-calendar-period-button:hover {
    background: none;
    outline: none;
  }
  .mat-button-focus-overlay {
    background: none;
  }
  /* arrow colors */
  .mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
    color: #9CA3AF;
    outline: none;
  }
  .mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button:hover, .mat-datepicker-content .mat-calendar-previous-button:hover {
    color: #4B5563;
  }
  .mat-calendar-header {
    padding: 0 0.9rem 0 1.5rem !important;
  }
  .mat-calendar-table-header tr th {
    font-size: .75rem;
    font-weight: 500;
    color: #111827;
  }
  /* separator line */
  .mat-calendar-table-header-divider::after {
    display: none;
  }

  .mat-calendar-body-label {
    opacity: 0;
  }
  .mat-calendar-body-label[colspan="7"] {
    display: none;
  }
  .mat-calendar-content {
    padding: 0 1rem 0 1rem !important;
  }

  .mat-datepicker-actions {
    padding: 0 1.5rem 1.5rem 1.5rem !important;
  }

  .mat-calendar-body-selected {
    background-color: #5850ec;
  }
  /*.mat-calendar-body-in-range::before {*/
  /*  background-color: var(--outline-color);*/
  /*}*/
  /*!* hover color of day before click *!*/
  /*.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {*/
  /*  background-color: var(--outline-color);*/
  /*}*/
  /* datepicker inputs styles */
  .mat-date-range-input-container {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity));
    font-size: 0.75rem;
    font-weight: 400;
  }

  .max-w-subtitle {
    max-width: 60px;
  }
  @media (min-width: 1280px) {
    .max-w-subtitle {
      max-width: 60px;
    }
  }
  @media (min-width: 1536px) {
    .max-w-subtitle {
      max-width: 90px
    }
  }
  /*!* Classes from tailwind 2*/
  /* todo: remove after migration*!*/
  /*@media (min-width: 1536px){*/
  /*  .container{*/
  /*    max-width: 1536px;*/
  /*  }*/
  /*}*/
}

/**
  ngneat dialog override
 */
.ngneat-dialog-content {
  box-shadow: none !important;
  background: transparent !important;
  overflow: auto !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.ngneat-dialog-content::-webkit-scrollbar {
  display: none !important;
}
.confirmation-dialog .ngneat-dialog-backdrop {
  z-index: 1001 !important;
}
.ngneat-dialog-backdrop {
  z-index: 100 !important;
  padding: unset !important;
}
.ngneat-dialog-backdrop.ngneat-dialog-backdrop-visible {
  background: rgba(107, 114, 128, 0.7) !important;
}

/*swiper override*/
.swiper-with-bg .swiper-slide {
  background-color: #ffffff !important;
}
.swiper-button-prev {
  left: 10px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMTUuNDEgMTYuNTlMMTAuODMgMTJsNC41OC00LjU5TDE0IDZsLTYgNiA2IDYgMS40MS0xLjQxeiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMFYweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
  width: 40px;
  height: 40px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 31px;
  background-position: 50%;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
}

.swiper-button-next {
  right: 10px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNOC41OSAxNi41OUwxMy4xNyAxMiA4LjU5IDcuNDEgMTAgNmw2IDYtNiA2LTEuNDEtMS40MXoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
  width: 40px;
  height: 40px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 31px;
  background-position: 50%;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
}
.swiper-pagination-bullet {
  border: 2px solid #fff;
  z-index: 999999;
  opacity: 1;
  background-color: transparent;
}
.swiper-pagination-bullet-active {
  background-color: #fff;
}
.swiper-pagination-fraction {
  font-size: smaller;
  font-weight: 600;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

html {
  scroll-behavior: smooth;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
* {
  scrollbar-width: thin;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 12px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-500 !important;
  border-radius: 50px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500 !important;
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  position: relative!important;
  contain: initial!important;
}

/* uppy */
.uppy-Dashboard-inner {
  width: 100% !important;
  height: 400px !important;
}
.uppy-StatusBar-actions, .uppy-DashboardContent-addMore, .uppy-Dashboard-Item-action--remove{
  display: none !important;
}

.uppy-custom-invalid .uppy-Dashboard-inner {
  @apply border !important;
  @apply rounded !important;
  border-color: var(--error-color)!important;
}

.uppy-Dashboard-AddFiles::before {
  @apply w-12 !important;
  @apply h-12 !important;
  /* using add-image-with-plus icon with a bg-gray-400 color*/
  content: url("data:image/svg+xml,%3Csvg stroke='%239ca3af' fill='none' viewBox='0 0 48 48' aria-hidden='true' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
}

.uppy-Dashboard-AddFiles-title {
  @apply text-base !important;
  @apply text-gray-600 !important;
}

.uppy-Dashboard-AddFiles-title > button {
  @apply text-base !important;
  @apply font-medium !important;
  color: var(--secondary-color) !important;
}

.uppy-Dashboard-AddFiles-title > button:hover {
  color: var(--secondary-color-dark) !important;
}

.uppy-Dashboard-AddFiles-title > button:focus {
  text-decoration: none;
}

.uppy-Dashboard-browse:hover, .uppy-Dashboard-browse:focus {
  border-bottom: none;
}

/* custom classes */
.mkx-card {
  @apply block  !important;
  @apply p-6 !important;
  @apply bg-white !important;
  @apply rounded-lg !important;
  @apply border  !important;
  @apply border-gray-200 !important;
  @apply shadow-md !important;
}

.tailwind-tooltip {
  @apply font-medium !important;
  @apply cursor-pointer !important;
  @apply ml-1 !important;
}

.tooltip-container {
  @apply bg-gray-400 !important;
  @apply rounded-md !important;
  @apply px-2 !important;
  @apply py-2 !important;
  @apply text-white !important;
  @apply text-lg sm:text-base !important;
  @apply leading-5 !important;
  @apply antialiased !important;
  @apply font-medium !important;
  @apply cursor-pointer !important;
  @apply break-normal !important;
}

.tailwind-input {
  @apply block !important;
  @apply w-full !important;
  @apply border;
  @apply rounded-md;
  @apply border-gray-300 !important;
  @apply px-3 !important;
  @apply py-2 !important;
  @apply sm:text-base !important;
  @apply text-gray-900 !important;
}

.tailwind-input[readonly] {
  @apply bg-gray-50 !important;
}

.tailwind-button {
  @apply w-full !important;
  @apply inline-flex !important;
  @apply justify-center !important;
  @apply rounded-md !important;
  @apply border !important;
  @apply border-transparent !important;
  @apply py-2 !important;
  @apply px-4 !important;
  @apply text-base !important;
  @apply font-medium !important;
  @apply shadow-sm !important;
  @apply focus:outline-none !important;
  @apply focus:ring-2 !important;
  @apply focus:ring-offset-2 !important;
}

.tailwind-link {
  @apply font-medium !important;
  @apply leading-5 !important;
  @apply focus:outline-none !important;
  @apply focus:underline !important;
  @apply transition !important;
  @apply ease-in-out !important;
  @apply duration-150 !important;
  @apply cursor-pointer !important;
}

.tailwind-checkbox {
  @apply flex !important;
  @apply items-center !important;
}

/* PrimeNG */
.p-component {
  @apply text-base !important;
}

.p-button.p-button-icon-only {
  padding: 0.5rem 0;
}

.p-inputgroup-addon {
  @apply sm:text-base !important;
  padding: 0.5rem 0.75rem;
}

.p-button {
  @apply sm:text-base !important;
  @apply font-medium !important;
  @apply w-full !important;
  padding: 0.5rem 0.75rem;
}

.p-inputswitch {
  @apply w-10 !important;
  @apply h-5 !important;
}
.p-inputswitch .p-inputswitch-slider:before {
  width: 1rem;
  height: 1rem;
  left: 2px;
  margin-top: -0.5rem;
}

.p-inputtext[disabled] {
  @apply bg-gray-100 !important;
  @apply cursor-not-allowed !important;
  opacity: 1 !important;
}

.p-inputtext {
  @apply placeholder-gray-400 !important;
}
.p-accordion p-accordiontab .p-accordion-tab {
  @apply mb-2 !important;
}
.p-accordion-header-link {
  background: white!important;
  border-color: white!important;
  padding: 0!important;
  margin: 0!important;
}
.p-accordion-content {
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  @apply px-2 !important;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none!important;
}
.pi {
  @apply text-base !important;
}

/** ng select **/
.ng-dropdown-panel.ng-select-bottom {
  margin-top: 0px !important;
}

/* AG Grid */
.ag-ltr .ag-body-horizontal-scroll {
  height: 12px!important;
  max-height: 12px!important;
  min-height: 12px!important;
}
.ag-body-horizontal-scroll-viewport {
  height: 12px!important;
  max-height: 12px!important;
  min-height: 12px!important;
}

.ag-row .ag-cell {
  display: grid;
  align-items: center;
}

/** CDK scroll **/
.cdk-virtual-scroll-spacer {
  height: 1px!important;
}

img.blur, .mkx-chart.blur{
  filter: blur(10px);
}
.blur:not(img) {
  color: transparent!important;
  text-shadow: 2px 0px 0.4em #a7a7a7 !important;
}

.dba-score-star {
  color: #ffc107;
}

/** Side nav tab elements **/
.side-nav-element {
  @apply border-transparent !important;
  @apply cursor-pointer !important;
  @apply text-gray-500 !important;
  @apply group  !important;
  @apply inline-flex !important;
  @apply items-center !important;
  @apply py-4 !important;
  @apply px-10 !important;
  @apply border-b-2 !important;
  @apply font-medium !important;
  @apply text-base !important;
  @apply font-medium !important;
}
.side-nav-element:hover{
  @apply text-gray-700 !important;
  @apply border-gray-300 !important;
}
.side-nav-element.active, .side-nav-element.active:hover {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color)!important;
}
