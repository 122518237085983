/**** VARS ****/
:root {
  --primary-color-light: #a5b4fc;
  --primary-color: #6366f1;
  --primary-color-dark: #3730a3;
  --secondary-color-light: #fdba74;
  --secondary-color: #f97316;
  --secondary-color-dark: #9a3412;
  --error-color: #dc2626;
  --openai-text-color: #10a37f;
  --openai-bg-color: #e6f3f3;
}

/**** COMMON ****/
.text-primary, .hover\:text-primary:hover, .active\:text-primary:active {
  color: var(--primary-color) !important;
}
.text-primary-light, .hover\:text-primary-light:hover, .active\:text-primary-light:active {
  color: var(--primary-color-light) !important;
}
.text-primary-dark, .hover\:text-primary-dark:hover, .active\:text-primary-dark:active {
  color: var(--primary-color-dark) !important;
}
.bg-primary, .hover\:bg-primary:hover, .active\:bg-primary:active {
  background-color: var(--primary-color) !important;
}
.bg-primary-light, .hover\:bg-primary-light:hover, .active\:bg-primary-light:active {
  background-color: var(--primary-color-light) !important;
}
.bg-primary-dark, .hover\:bg-primary-dark:hover, .active\:bg-primary-light:active {
  background-color: var(--primary-color-dark) !important;
}
.border-primary, .hover\:border-primary:hover, .active\:border-primary:active {
  border-color: var(--primary-color) !important;
}
.border-primary-light, .hover\:border-primary-light:hover, .active\:border-primary-light:active {
  border-color: var(--primary-color-light) !important;
}
.border-primary-dark, .hover\:border-primary-dark:hover, .active\:border-primary-dark:active {
  border-color: var(--primary-color-dark) !important;
}

.text-secondary, .hover\:text-secondary:hover, .active\:text-secondary:active {
  color: var(--secondary-color) !important;
}
.text-secondary-light, .hover\:text-secondary-light:hover, .active\:text-secondary-light:active {
  color: var(--secondary-color-light) !important;
}
.text-secondary-dark, .hover\:text-secondary-dark:hover, .active\:text-secondary-dark:active {
  color: var(--secondary-color-dark) !important;
}
.bg-secondary, .hover\:bg-secondary:hover, .active\:bg-secondary:active {
  background-color: var(--secondary-color) !important;
}
.bg-secondary-light, .hover\:bg-secondary-light:hover, .active\:bg-secondary-light:active {
  background-color: var(--secondary-color-light) !important;
}
.bg-secondary-dark, .hover\:bg-secondary-dark:hover, .active\:bg-secondary-light:active {
  background-color: var(--secondary-color-dark) !important;
}
.border-secondary, .hover\:border-secondary:hover, .active\:border-secondary:active {
  border-color: var(--secondary-color) !important;
}
.border-secondary-light, .hover\:border-secondary-light:hover, .active\:border-secondary-light:active {
  border-color: var(--secondary-color-light) !important;
}
.border-secondary-dark, .hover\:border-secondary-dark:hover, .active\:border-secondary-dark:active {
  border-color: var(--secondary-color-dark) !important;
}
.ring-secondary-color {
  --tw-ring-color: var(--secondary-color) !important;
}

.text-openai, .hover\:text-openai:hover, .active\:text-openai:active {
  color: var(--openai-text-color) !important;
}

/**** FORMS ****/
.tailwind-input:focus {
  border-color: var(--secondary-color)!important;
  --tw-ring-color: var(--secondary-color)!important;
}
.ng-dirty.ng-touched.ng-invalid.tailwind-input {
  border-color: var(--error-color)!important;
  --tw-ring-color: var(--error-color)!important;
}

/**** Buttons ****/
.tailwind-button.tailwind-button-secondary {
  @apply text-white !important;
  background: var(--secondary-color) !important;
}
.tailwind-button.tailwind-button-secondary:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--secondary-color), 0 1px 2px 0 rgb(0 0 0 / 0%);
  background: var(--secondary-color-dark) !important;
  --tw-ring-color: var(--secondary-color)!important;
}
.tailwind-button.tailwind-button-white {
  @apply border-gray-300 !important;
  @apply text-gray-700 !important;
  @apply bg-white !important;
  @apply hover:bg-gray-50 !important;
}
.tailwind-button.tailwind-button-white:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--secondary-color), 0 1px 2px 0 rgb(0 0 0 / 0%);
  background: #ffffff !important;
  --tw-ring-color: var(--secondary-color)!important;
}
.tailwind-button.tailwind-button-red {
  @apply border-transparent !important;
  @apply text-white !important;
  @apply bg-red-600 !important;
  @apply hover:bg-red-500 !important;
  @apply focus:border-red-700 !important;
}
.tailwind-button.tailwind-button-reverse-indigo {
  @apply border-transparent !important;
  @apply text-indigo-600 !important;
  @apply bg-indigo-100 !important;
  @apply hover:bg-indigo-50 !important;
  @apply focus:border-indigo-900 !important;
}

.tailwind-link {
  color: var(--secondary-color) !important;
}

.tailwind-link:hover {
  color: var(--secondary-color-dark) !important;
}

/** input text **/
.p-inputtext:enabled:focus{
  box-shadow: 0 0 0 1px var(--secondary-color)!important;
}
.ng-dirty.ng-touched.ng-invalid.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 1px var(--error-color)!important;
  border-color: var(--error-color)!important;
  --tw-ring-color: var(--error-color)!important;
}
.ng-dirty.ng-touched.ng-invalid.p-inputwrapper .p-inputtext:enabled:focus {
  box-shadow: 0 0 0 1px var(--error-color)!important;
  border-color: var(--error-color)!important;
  --tw-ring-color: var(--error-color)!important;
}
mkx-input-field-preview.ng-dirty.ng-touched.ng-invalid .p-inputtext {
  box-shadow: 0 0 0 1px var(--error-color)!important;
  border-color: var(--error-color)!important;
  --tw-ring-color: var(--error-color)!important;
}

/** input number **/
.ng-dirty.ng-touched.ng-invalid:not(form) .p-element .tailwind-input.p-inputnumber-input:enabled {
  border-color: var(--error-color)!important;
}

.ng-dirty.ng-touched.ng-invalid:not(form) .p-element .tailwind-input.p-inputnumber-input:enabled:focus{
  box-shadow: 0 0 0 1px var(--error-color)!important;
  border-color: var(--error-color)!important;
  --tw-ring-color: var(--error-color)!important;
}

/** switch **/
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--secondary-color) !important;
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 2px var(--secondary-color)!important;
}

/** slider **/
.p-slider .p-slider-handle:focus {
  box-shadow: 0 0 0 1px var(--secondary-color)!important;
}
.p-slider .p-slider-range {
  background: var(--secondary-color);
}
.p-slider .p-slider-handle {
  border-color: var(--secondary-color);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

/** radio button **/
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--secondary-color);
  background: var(--secondary-color);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--secondary-color);
  background: var(--secondary-color);
  color: #ffffff;
}
.p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--secondary-color), 0 1px 2px 0 rgb(0 0 0 / 0%) !important;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--secondary-color);
  border-color: var(--secondary-color);
}

/** checkbox **/
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--secondary-color), 0 1px 2px 0 rgb(0 0 0 / 0%) !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: var(--secondary-color);
}
.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--secondary-color);
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--secondary-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--secondary-color);
  background: var(--secondary-color);
  color: #ffffff;
}

/** tabmenu **/
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}
:host ::ng-deep .p-tabmenu .p-tabmenu-nav-btn.p-link {
  color: var(--secondary-color);
}

/** ng select **/
ng-select.ng-select.ng-select-focused>.ng-select-container {
  border-color: var(--secondary-color)!important;
  border: 2px solid var(--secondary-color) !important;
  --tw-ring-color: var(--secondary-color)!important;
  box-shadow: 0 0 0 1px var(--secondary-color);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected
{
  background-color: var(--secondary-color) !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
  background-color: var(--secondary-color) !important;
}
mkx-ng-api-select.ng-dirty.ng-touched.ng-invalid .ng-select.ng-select-focused>.ng-select-container {
  border-color: var(--error-color)!important;
  --tw-ring-color: var(--error-color)!important;
}
mkx-ng-api-select.ng-dirty.ng-touched.ng-invalid .ng-select .ng-select-container {
  border-color: var(--error-color)!important;
}

/** button **/
.p-button {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color)!important;
}
.p-button:enabled:active {
  background: var(--secondary-color-dark) !important;
  border-color: var(--secondary-color-dark)!important;
}
.p-button:enabled:hover {
  background-color: var(--secondary-color-dark) !important;
  border-color: var(--secondary-color-dark)!important;
}
.p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--secondary-color-light), 0 1px 2px 0 rgb(0 0 0 / 0%);
}

